/**
 * Generates a random integer between two values (inclusive).
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum value of the range.
 * @returns {number} A random integer within the specified range.
 */
export function getRandInt(min: number, max: number): number {
	min = Math.ceil(min);
	max = Math.floor(max);
	if ( max <= min ) {
		return min;
	} else {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
}
