import { useEffect, useRef } from "react";
import { z } from "zod";

import { useGlobal } from "~/context/global";
import { useStabilizeObject } from "~/hooks/useStabilize";
import { spawnDetached } from "~/utils/async";

export const userPreferencesSchema = z.object({
	energySliderValue: z.number().min(0).max(100).optional(),
});

export type UserPreferences = z.infer<typeof userPreferencesSchema>;

const LOCAL_STORAGE_KEY = "buha-user-preferences";

export function useRestoreUserPreferences(): void {
	const { setGlobal } = useGlobal();

	useEffect(() => {
		spawnDetached(async () => {
			const prefsJson = window.localStorage.getItem(LOCAL_STORAGE_KEY);
			if (prefsJson === null) {
				return;
			}

			const prefsJs = JSON.parse(prefsJson);
			const prefsRes =
				await userPreferencesSchema.safeParseAsync(prefsJs);
			if (!prefsRes.success) {
				console.warn(
					"Error processing stored userPreferences data",
					prefsRes.error,
					"stored data:",
					prefsJs,
				);
				return;
			}
			const prefs = prefsRes.data;

			setGlobal((g) => {
				if (prefs.energySliderValue !== undefined) {
					g.energySliderValue = prefs.energySliderValue;
				}
			});
		});
	}, [setGlobal]);
}

export function useStoreUserPreferences(): void {
	const { energySliderValue } = useGlobal();

	// needed to not override prefs with the default values on initial page load
	const valueToStore: Required<UserPreferences> = useStabilizeObject({
		energySliderValue,
	});
	const storedValueRef = useRef(valueToStore);

	useEffect(() => {
		// skip if there are no changes
		if (storedValueRef.current === valueToStore) {
			return;
		}

		storedValueRef.current = valueToStore;

		window.localStorage.setItem(
			LOCAL_STORAGE_KEY,
			JSON.stringify(valueToStore),
		);
	}, [valueToStore]);
}
