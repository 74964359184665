import type { PropsWithChildren } from "react";
import { IntlProvider } from "react-intl";

import { DEFAULT_LANGUAGE } from "~/hooks/useLanguage";
import messagesEn from "~/lang/en";
import messagesDe from "~/lang/de";
import usePageData from "~/hooks/usePageData";

export type PageIntlProviderProps = PropsWithChildren;

export default function PageIntlProvider({ children }: PageIntlProviderProps) {
	const pageData = usePageData();
	const language =
		pageData?.i18n.find((lang) => lang.active === 1)?.twoLetterIsoCode ??
		DEFAULT_LANGUAGE;

	const messages = language === "en" ? messagesEn : messagesDe;

	return (
		<IntlProvider messages={messages} locale={language}>
			{children}
		</IntlProvider>
	);
}
