import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { delay, spawnDetached } from "~/utils/async";
import { cn } from "~/utils/cn";
import { getRandInt } from "~/utils/random";
import { useGlobal } from "~/context/global";

const ANIMATION_DURATION = 800;

type State = "init" | "before-loading" | "loading" | "after-loading" | "hidden";

export default function WhiteLoader() {
	const { setGlobal } = useGlobal();
	const [animation, setAnimation] = useState("default");
	const [state, setState] = useState<State>("init");

	useEffect(() => {
		spawnDetached(async () => {
			try {
				if (process.env.NODE_ENV === "development") {
					return;
				}

				setState("before-loading");
				setAnimation(String(getRandInt(1, 3)));
				await delay(20);
				setState("loading");
				await delay(ANIMATION_DURATION + 200);
				// TODO: wait here for page images to load?!
				setState("after-loading");
				await delay(ANIMATION_DURATION);
			} finally {
				setState("hidden");
			}
		});
	}, []);

	useEffect(() => {
		if (state === "hidden") {
			setGlobal((g) => {
				g.isPageLoaded = true;
			});
		}
	}, [state, setGlobal]);

	if (state === "init") {
		return null;
	}

	return (
		<AnimatePresence>
			{state !== "hidden" && (
				<motion.div
					id="white-loader"
					className={cn(`ani-${animation}`, state)}
					initial={{ opacity: 1 }}
					exit={{ opacity: 0 }}
				>
					<div id="burg-b">
						<svg
							version="1.1"
							viewBox="0 0 36.76 43.413"
							xmlSpace="preserve"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g transform="matrix(1.3333 0 0 -1.3333 0 43.413)">
								<g transform="scale(.1)">
									<path d="m149.86 132.8h-65.476v-68.25h64.09c28.593 0 42.886 11.531 42.886 34.586 0 21.675-13.375 33.664-41.5 33.664zm-10.605 129.11h-54.871v-64.098h54.406c23.059 0 37.355 11.532 37.355 32.743 0 20.289-12.453 31.355-36.89 31.355zm23.515-261.92h-162.77v325.55h162.32c61.786 0 97.754-34.121 97.754-86.688 0-27.672-12.453-50.722-31.816-66.867 31.355-17.519 47.492-44.266 47.492-81.156 0-55.332-41.5-90.84-112.97-90.84" />
								</g>
							</g>
						</svg>
					</div>
					<div id="burg-u">
						<svg
							version="1.1"
							viewBox="0 0 38.36 44.147"
							xmlSpace="preserve"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g transform="matrix(1.3333 0 0 -1.3333 0 44.147)">
								<g transform="scale(.1)">
									<path d="m287.74 143.87c0-93.609-57.636-143.87-143.87-143.87-86.23 0-143.87 50.258-143.87 143.87v187.22h85.77v-190.91c0-40.582 18.906-64.094 58.102-64.094s58.102 23.512 58.102 64.094v190.91h85.765v-187.22" />
								</g>
							</g>
						</svg>
					</div>
					<div id="burg-r">
						<svg
							version="1.1"
							viewBox="0 0 39.413 43.413"
							xmlSpace="preserve"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g transform="matrix(1.3333 0 0 -1.3333 0 43.413)">
								<g transform="scale(.1)">
									<path d="m148.48 256.85h-62.711v-78.856h60.871c26.285 0 42.879 14.293 42.879 40.117 0 24.442-15.215 38.739-41.039 38.739zm147.1-256.85h-93.605l-41.5 72.856c-11.528 19.832-23.059 31.36-38.735 35.968h-35.968v-108.82h-85.766v325.55h163.23c65.481 0 111.59-42.422 111.59-105.14 0-44.266-25.359-77.008-63.172-92.684 12.914-8.761 20.75-19.832 29.051-34.121l54.871-93.609" />
								</g>
							</g>
						</svg>
					</div>
					<div id="burg-g">
						<svg
							version="1.1"
							viewBox="0 0 40.267 44.88"
							xmlSpace="preserve"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g transform="matrix(1.3333 0 0 -1.3333 0 44.88)">
								<g transform="scale(.1)">
									<path d="m227.79 230.1c-13.832 20.75-34.586 34.586-64.555 34.586-48.879 0-77.468-31.36-77.468-96.836 0-69.172 29.05-99.141 78.39-99.141 19.828 0 37.348 3.2266 53.028 11.527v43.805h-57.18v69.168h138.8v-148.02c-34.125-25.363-77.469-45.191-138.34-45.191-100.06 0-160.47 57.176-160.47 167.85 0 110.21 59.945 168.77 158.63 168.77 70.086 0 111.59-27.207 143.41-74.238l-74.242-32.281" />
								</g>
							</g>
						</svg>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
}
