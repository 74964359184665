import { type MessageIds } from "~/lang";

const messages: Record<MessageIds, string> = {
	websiteTitle: "Burg Giebichenstein Kunsthochschule Halle",
	"pageTitleLabel.studiengang": "Studiengang",
	"pageTitleLabel.studienrichtung": "Studienrichtung",
	"pageTitleLabel.schwerpunkt": "Schwerpunkt",
	"pageTitleLabel.einrichtung": "Einrichtung",

	"sectionProjectsLabel.studiengang":
		"Studienarbeiten des Studiengangs {title}",
	"sectionProjectsLabel.studienrichtung":
		"Studienarbeiten der Studienrichtung {title}",
	"sectionProjectsLabel.schwerpunkt":
		"Studienarbeiten des Schwerpunktes {title}",
	"sectionProjectsLabel.einrichtung": "Projekte in der Einrichtung {title}",

	"navigation.works": "Studienarbeiten",
	"navigation.archive": "Archiv",

	"energySlider.energySaverMode": "Energiesparmodus",
	"energySlider.animationMode": "Animationsmodus",
	calendar: "Kalender",
	"calendar.inSection": "Termine {section} ({count})",
	"calendar.today": "Heute ({count})",
	"calendar.highlightsInNearFuture": "Highlights in nächster Zeit",
	"calendar.ongoing": "Laufend ({count})",
	"calendar.upcoming": "Demnächst ({count})",
	"calendar.allEvents": "Alle Termine",
	"loginStatus.loading": "Lade den Login…",
	"loginStatus.loggedIn": "Eingeloggt als {username}",
	"loginStatus.login": "Login",
	"frication.open": "Friction öffnen",
	"ceGroup.expand": "Ausklappen",
	"ceGroup.collapse": "Einklappen",
	"ceText.expand": "Text ausklappen",
	"ceText.collapse": "Text einklappen",
	"ceImage.noImage": "Kein Bild vorhanden",

	menu: "Menü",
	all: "Alle",
	search: "Suche",
	loadMore: "Mehr laden",
	more: "Mehr",
	noResults: "Kein Ergebnis gefunden.",

	"toolbar.search": "Suche",
	"toolbar.application": "Bewerben",
	"toolbar.Lehrangebote": "Lehrangebote",
	"toolbar.Studienarbeiten": "Studienarbeiten",
	"toolbar.media": "Media",
	"toolbar.press": "Presse",
	"toolbar.tools": "Tools",
	"toolbar.map": "Karte",
	"toolbar.toggleCineMode": "CineMode umschalten",

	"solr.result.pagesTitleSuffix": " (Seite)",

	"solr.title.default": "Suche",
	"solr.title.article": "Artikel",
	"solr.title.event": "Termine",
	"solr.title.lehrangebot": "Lehrangebote",
	"solr.title.person": "Personen",
	"solr.title.personType": "Personen",
	"solr.title.former": "Ehemalige",
	"solr.title.project": "Projekte",
	"solr.title.publication": "Publikationen",
	"solr.title.sectionProject": "Projekte",
	"solr.title.sectionArchive": "Archiv",
	"solr.title.media": "Media",
	"solr.title.aToZ": "Burg A—Z",
	"solr.title.news": "Aktuelles",

	"solr.all.default": "Alle Suchtreffer",
	"solr.all.article": "Alle Artikel",
	"solr.all.event": "Alle Termine",
	"solr.all.lehrangebot": "Alle Lehrangebote",
	"solr.all.person": "Alle Personen",
	"solr.all.personType": "Alle Personen",
	"solr.all.former": "Ehemalige",
	"solr.all.project": "Alle Projekte",
	"solr.all.publication": "Alle Publikationen",
	"solr.all.sectionProject": "Alle Projekte",
	"solr.all.sectionArchive": "Alle Artikel",
	"solr.all.news": "Alle Artikel",
	"solr.all.media": "Alle Medien",

	"eventType.default": "Termin",
	"eventType.1": "Jour Fixe",
	"eventType.2": "Kino",
	"eventType.3": "Präsentation",
	"eventType.4": "Prüfung",
	"eventType.5": "Stipendium",
	"eventType.6": "Vortrag",
	"eventType.7": "Wettbewerb",
	"eventType.8": "Termin (Sonstiges)",
	"eventType.9": "Ausstellung",
	"eventType.10": "Diplom",
	"eventType.11": "Gremium",

	"projectType.default": "Studienarbeit",
	"projectType.groupProject": "Gruppenarbeit",
	"projectType.1": "Studienarbeit 1.J.",
	"projectType.2": "Studienarbeit 2.J.",
	"projectType.3": "Studienarbeit ab 3.J.",
	"projectType.4": "Bachelor-Projekt (BA)",
	"projectType.5": "Freies Projekt",
	"projectType.6": "Studienarbeit",
	"projectType.7": "Masterthesis (MA)",
	"projectType.9": "Diplom",
	"projectType.10": "Privat",
	"projectType.11": "Forschungsprojekt",
	"projectType.12": "Gefördertes Projekt",
	"projectType.13": "Studienprojekt",
	"projectType.14": "Staatsexamen",

	"personPage.contact": "Standort/Kontakt",

	"projectEssentialsTitle.backToProjects": "Zurück zu den Studienarbeiten",
	"projectEssentialsTitle.backToGroupProject": "Zurück zur Gruppenarbeit",
	"projectEssentialsTitle.groupProject": "Gruppenarbeit",
	"projectEssentialsTitle.project": "Studienarbeit",

	"studentsEssentialsTitle.students": "Studierende",
	"studentsEssentialsTitle.student": "Studierende:r",

	"vimeoVideo.noConsent":
		"Keine Zustimmung für Vimeo gegeben. Das eingebettete Video wird deshalb nicht angezeigt.{br}<showConsentModal>Cookie-Einstellungen öffnen</showConsentModal>{br}<videoLink>Video bei Vimeo ansehen</videoLink>",
	"youtubeVideo.noConsent":
		"Keine Zustimmung für Youtube gegeben. Das eingebettete Video wird deshalb nicht angezeigt.{br}<showConsentModal>Cookie-Einstellungen öffnen</showConsentModal>{br}<videoLink>Video bei Youtube ansehen</videoLink>",
	"podigeePodcast.noConsent":
		"Keine Zustimmung für Podigee gegeben. Der eingebettete Podcast wird deshalb nicht angezeigt.{br}<showConsentModal>Cookie-Einstellungen öffnen</showConsentModal>{br}<podcastLink>Podcast bei Podigee anhören</podcastLink>",

	addToCalendar: "In Kalender eintragen",

	courseSection: "Studiengang / -richtung",
	courseEcts: "ECTS/LN",
	courseEinschreibefrist: "Anmeldeschluss",
	courseRegistrationStart: "Anmeldebeginn",
	courseRegistrationEnd: "Anmeldeschluss",
	courseBegin: "Kursbeginn",
	coursePlace: "Ort",
	courseTime: "Zeit",
	courseBesprechungszeit: "Konsultationszeit(en)",
	coursePflichtkennzeichen: "Pflicht/Wahlpflicht/Wahl",
	courseRequirements: "Voraussetzungen",
	courseErgaenzungsangebote: "Zusatzangebote",
	courseStudienjahr: "Studienjahr",
	courseGuests: "Gäste",
	courseFinalDegree: "Abschluss",
	courseBenotet: "Benotung",
	courseBezeichnung: "Modulbezeichnung / Fachbezeichnung",
	courseKontakt: "Kontakt",
	courseModulAngebotTitle: "Modulbereich Design / Angebote Kunst",
	courseSemester: "Semester",
	courseSemesterTyp: "Semester type",
	courseSemesterYear: "Semester year",
	courseVeranstaltungsart: "Veranstaltungsart",
	courseWochentypString: "Wochentyp",
	courseZeitenUndTermine: "Zeiten und Termine",

	place: "Ort",
	time: "Zeit",

	urlFormer: "hochschule/information/personen/ehemalige",

	"courseSemesterTyp.WiSe": "Wintersemester",
	"courseSemesterTyp.SoSe": "Sommersemester",

	"relatedPersonsTitle.teachers": "Lehrende(r)",
	"relatedPersonsTitle.default": "Personen",

	"form.submit": "Absenden",
	"form.previous": "Vorherige Seite",
	"form.next": "Nächste Seite",
	"form.submitted": "Erfolgreich versendet!",

	"relatedProjects.subProjects.titleHead": "Ergebnisse",
	"relatedProjects.subProjects.titleLabel":
		"Studienarbeiten des Gruppenprojekts „{articleTitle}”",
	"relatedProjects.siblingProjects.titleHead":
		"Weitere Ergebnisse des Gruppenprojekts {articleParentTitle, select, undefined {} other { „{articleParentTitle}”}}",

	"home.media.title": "Media",
	"home.media.allMedia": "Alle Medien",

	"newsletterSignup.email": "E-Mail",
	"newsletterSignup.submit": "Zum Newsletter anmelden",

	"aToZ.forTeachers": "Für Lehrende",
	"aToZ.forManagement": "Für Verwaltung",
	"aToZ.forStudents": "Für Studierende",
	"aToZ.applications": "Anträge",

	"mainNav.kunst": "Kunst",
	"mainNav.design": "Design",
	"mainNav.studium": "Studium",
	"mainNav.werkstatten": "Werkstätten",
	"mainNav.einrichtungen": "Einrichtungen",
	"mainNav.personen": "Personen",
	"mainNav.institution": "Institution",
};

export default messages;
