import { defer } from "@remix-run/node";
import type { LoaderFunctionArgs, LinksFunction } from "@remix-run/node";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLocation,
} from "@remix-run/react";
import type { CSSProperties } from "react";
import { Suspense, lazy, useEffect, useMemo, useState } from "react";

import {
	Provider as GlobalProvider,
	energySliderValueIsDark,
	useGlobal,
} from "~/context/global";
import { getInitialData } from "~/modules/typo3/initial-data";
import { getLoginStatus } from "~/modules/typo3/login";
import {
	getAdjustedColors,
	rgbColorToStyleColor,
	rgbColorToTwVar,
} from "~/utils/colors";
import { cn } from "~/utils/cn";
import PageIntlProvider from "~/components/PageIntlProvider";
import WhiteLoader from "~/components/WhiteLoader";
import DevNav from "~/components/DevNav";
import useLanguage from "~/hooks/useLanguage";
import useRootData from "~/hooks/useRootData";
import { desktopBreakpoint } from "~/utils/breakpoints";
import { unreachableValue } from "~/utils/unreachable";
import {
	useRestoreUserPreferences,
	useStoreUserPreferences,
} from "~/modules/userPreferences";
import { delay, spawnDetached } from "~/utils/async";
import ConsentModal from "~/components/ConsentModal";
import "../styles/base.scss";
import usePageData from "~/hooks/usePageData";

const EmailRoot = lazy(() => import("~/components/email/EmailRoot"));
const Funcs = lazy(() => import("~/components/functionality/Funcs"));

export const links: LinksFunction = () => {
	return [];
};

/*
	<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
	<script src="https://cdn.jsdelivr.net/npm/ismobilejs@1.1.1/dist/isMobile.min.js" integrity="sha256-M9uZsv3qfi72WQfG+zH1TBvHgZZon/yNrCZjcJCo644=" crossorigin="anonymous"></script>
	<script src="https://cdnjs.cloudflare.com/ajax/libs/tilt.js/1.2.1/tilt.jquery.min.js" crossorigin="anonymous"></script>
	<script src="https://player.vimeo.com/api/player.js"></script>
	<script src="https://unpkg.com/imagesloaded@5/imagesloaded.pkgd.min.js"></script>
	<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.hoverintent/1.10.2/jquery.hoverIntent.min.js" integrity="sha512-sw+oNcbTS/A0W2RCoTKChyNVw/OClp76mxn3yaOZiDaxpGMXFIWrZC6BlgtieaWp2oh2asgj44TJd1f4Lz2C8Q==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
 */

export const loader = async ({ request }: LoaderFunctionArgs) => {
	const loginStatusPromise = getLoginStatus(request);
	const lang = new URL(request.url).pathname.match(/^\/en(\/|$)/)
		? "en"
		: "de";

	const data = await getInitialData(lang);
	if (data instanceof Response) {
		return data;
	}

	if (data === undefined) {
		return new Response("Internal error (InitialData)", {
			status: 500,
			headers: {
				"content-type": "text/plain",
			},
		});
	}

	return defer({
		data,
		loginStatus: loginStatusPromise,
		isCrawler: request.headers.get("x-burg22-crawler") === "true",
	});
};

export const shouldRevalidate = () => false;

export default function App() {
	const isMobile = useIsMobile();
	const rootData = useRootData();
	const pageData = usePageData();

	if (pageData?.appearance.backendLayout === "newsletter") {
		return <EmailRoot page={pageData} />;
	}

	return (
		<PageIntlProvider>
			<GlobalProvider
				isMobile={isMobile ?? false}
				isMobileReal={isMobile}
				renderStrippedDownSite={rootData?.isCrawler ?? false}
			>
				<AppContent />
			</GlobalProvider>
		</PageIntlProvider>
	);
}

function useIsMobile(): null | boolean {
	const [isMobile, setIsMobile] = useState<null | boolean>(null);

	useEffect(() => {
		const isDesktopMq = matchMedia(`(min-width: ${desktopBreakpoint}px)`);

		const handleDesktopMqChange = () => {
			setIsMobile(!isDesktopMq.matches);
		};
		handleDesktopMqChange();

		isDesktopMq.addEventListener("change", handleDesktopMqChange);
		return () => {
			isDesktopMq.removeEventListener("change", handleDesktopMqChange);
		};
	}, []);

	return isMobile;
}

function AppContent() {
	const { renderStrippedDownSite } = useGlobal();

	useResetGlobalOnPageChange();
	useRestoreUserPreferences();
	useStoreUserPreferences();
	const bodyStyles = useBodyStyles();
	const bodyClasses = useBodyClasses();
	const lang = useLanguage();

	if (renderStrippedDownSite) {
		return (
			<html lang={lang}>
				<body>
					<Outlet />
				</body>
			</html>
		);
	}

	return (
		<html lang={lang} className="h-full">
			<head>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1"
				/>

				<link
					rel="icon"
					href="/fileadmin/frontend/favicon/favicon.svg"
				/>
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/fileadmin/frontend/favicon/favicon-180x180.png"
				/>
				<link
					rel="mask-icon"
					href="/fileadmin/frontend/favicon/favicon-mask.svg"
					color="#5bbad5"
				/>

				<link rel="manifest" href="/site.webmanifest" />

				<meta name="theme-color" content="#ffffff" />
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<Meta />
				<Links />

				<link
					rel="stylesheet"
					// Loading directly from K&R is super slow so we proxy it
					// href="https://burg.knoth-renner.com/msg-extern/style.css"
					href="/fileadmin/kr-css.php?v=2"
				/>
			</head>
			<body className={bodyClasses} style={bodyStyles}>
				<WhiteLoader />
				<ConsentModal />

				<Outlet />

				{import.meta.env.DEV && <DevNav />}
				<ScrollRestoration />
				<Scripts />

				<Suspense>
					<Funcs />
				</Suspense>
			</body>
		</html>
	);
}

function useResetGlobalOnPageChange() {
	const { setGlobal } = useGlobal();
	const locationKey = useLocation().key;

	useEffect(() => {
		return () => {
			setGlobal((g) => {
				g.colorOverride = null;
				g.isMobileNavOpen = false;
				g.isCalendarOpen = false;
				g.isExtend = false;
				g.isInCinemode = false;
				g.openToolbarOverlay = null;
				g.isInTransition = false;
			});
		};
	}, [setGlobal, locationKey]);
}

function useBodyStyles(): CSSProperties {
	const data = usePageData();
	const beLayout = data?.appearance.backendLayout;

	const { energySliderValue, colorOverride } = useGlobal();

	return useMemo(() => {
		const colors = getAdjustedColors(energySliderValue);

		switch (beLayout) {
			case "calendar":
				if (colors["cal-bg"]) {
					colors["nav-bg"] = colors["cal-bg"];
					colors["footer-bg"] = colors["cal-bg"];
				} else {
					console.warn('Could not get "cal-bg"');
				}
				break;
		}

		switch (colorOverride) {
			case null:
				break;

			case "friction":
				colors["main-bg"] = [220, 220, 220];
				colors["nav-bg"] = [220, 220, 220];
				break;

			default:
				unreachableValue(colorOverride);
		}

		const entries = Object.entries(colors).flatMap(
			([key, color]) =>
				[
					[`--color-${key}`, rgbColorToStyleColor(color)],
					[`--tw-color-${key}`, rgbColorToTwVar(color)],
				] as const,
		);
		const styles: CSSProperties = Object.fromEntries(entries);

		return styles;
	}, [energySliderValue, colorOverride, beLayout]);
}

function useBodyClasses(): string {
	const {
		isMobile,
		isMobileNavOpen,
		isCalendarOpen,
		isExtend,
		isInCinemode,
		isInTransition,
		isPageLoaded,
		energySliderValue,
	} = useGlobal();

	const [calendarMobileClassName, setCalendarMobileClassName] = useState("");

	useEffect(
		() =>
			spawnDetached(async (signal) => {
				if (!isMobile) {
					setCalendarMobileClassName("");
				}

				if (isCalendarOpen) {
					setCalendarMobileClassName("calendar-half-active");
					await delay(500, { signal });
					setCalendarMobileClassName(
						"calendar-half-active calendar-full-active",
					);
				} else {
					setCalendarMobileClassName("calendar-half-active");
					await delay(500, { signal });
					setCalendarMobileClassName("");
				}
			}),
		[isMobile, isCalendarOpen],
	);

	return cn(
		calendarMobileClassName,
		energySliderValueIsDark(energySliderValue)
			? "mode-dark save-energy"
			: "mode-light spend-energy",
		{
			"mobile-menu-active": isMobileNavOpen,
			"calendar-active": isCalendarOpen,
			extend: isExtend,
			cinemode: isInCinemode,
			transition: isInTransition,
			"page-loaded": isPageLoaded,
		},
	);
}
